//
// Custom Example 1 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//

.my-custom-style {
	padding: 1rem 0.5rem;
	color: kt-brand-color();

	.my-custom-element {
		margin-left: 1rem;
	}
}

@include kt-tablet-and-mobile {
	.my-custom-style {
		padding: 0.5rem 0.25rem;

		.my-custom-element {
			margin-left: 0.5rem;
		}
	}
}

.hover-span-action {
	cursor: pointer;
	color: rgba(0, 0, 0, 0.87);
	&:hover {
		color: #2e40d4;
	}
}

.hover-span-action-remove {
	cursor: pointer;
	&:hover {
		color: #dc3545;
	}
}

.starDanger:after {
	content: "*";
	color: #dc3545;
	padding-left: 5px;
}
.has-error {
	border-color: #dc3545 !important;
	&:focus {
		border-color: #dc3545 !important;
	}
}
.has-error-select {
	border-color: #dc3545 !important;
}
.has-error-single-img {
	width: 106px;
	height: 106px;
	border: 1px dashed #dc3545;
}

.has-error-single-file {
	width: 100px;
	height: 34px;
	border: 1px dashed #dc3545;
}

.remove-video {
	position: absolute;
	font-size: 20px;
	color: #ff0000;
	right: 2px;
	background: #fff;
	border-radius: 50%;
	cursor: pointer;
}
.PhoneInputInput {
	border: none;
}
.ant-select.form-control {
	padding: 0px !important;
	height: auto !important;
	min-height: 39px;
	.ant-select-selector {
		height: 100% !important;
		border: none !important;
		.ant-select-selection-search {
			width: 20% !important;
		}
	}
}
.ant-select.form-control.select-user {
	padding: 0px !important;
	height: auto !important;
	min-height: 39px;
	.ant-select-selector {
		height: 100% !important;
		border: none !important;
		.ant-select-selection-search {
			width: 20% !important;
		}
	}
}

.ant-picker-calendar-header .ant-picker-calendar-year-select {
	min-width: 100px;
}
.ant-picker-calendar-header .ant-picker-calendar-month-select {
	min-width: 100px;
	margin-left: 8px;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
	display: none;
}
.ant-select-multiple {
	.ant-select-selection-item-remove {
		.anticon {
			vertical-align: 0.1em !important;
			margin-left: 3px;
		}
	}
}

.events-training-list {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		font-size: 12px;
		margin-bottom: 4px;
		color: #fff;
		padding: 4px;
	}
	.offline {
		background: #e86a95;
	}
	.online {
		background: #6ca2f7;
	}
}

.btn-label-default {
	background-color: #eeeded;
	cursor: text !important;
}

.PhoneInputCountryIcon {
	svg {
		margin-top: -8px;
	}
}

dt,
dd {
	display: table-cell;
}

dd {
	float: right;
	margin-top: -20px;
	font-weight: bold;
}

.star-checkbox {
	font-size: 25px;
	cursor: pointer;
	opacity: 0;
	position: relative;
	z-index: 999;
}
.b:before {
	content: "\2605";
	position: absolute;
	visibility: visible;
	color: #eee;
	margin-top: -9px;
	font-size: 23px;
	top: 35%;
}
.star-checkbox:checked ~ .b:before {
	content: "\2605";
	position: absolute;
	color: #efd358;
}

.star-checkbox2 {
	font-size: 25px;
	cursor: pointer;
	opacity: 0;
	position: relative;
	z-index: 999;
}
.b2:before {
	content: "\2605";
	position: absolute;
	visibility: visible;
	color: #bdc1d8;
	margin-top: -20px;
	font-size: 26px;
	top: 35%;
	right: 0;
}
.star-checkbox2:checked ~ .b2:before {
	content: "\2605";
	position: absolute;
	color: #efd358;
}

.disabled-span {
	pointer-events: none;
	cursor: not-allowed;
}

.inquiryStatusNew {
	background-color: #dc3545 !important;
	font-weight: bold;
}

.ce-request {
	background-color: #dc3545 !important;
	font-weight: bold;
}
.cb-request {
	background-color: #dc3545 !important;
	font-weight: bold;
}
.customBtn {
	.MuiButtonBase-root.MuiIconButton-root {
		left: 15px !important;
	}
}
.btn-create-plan {
	&:hover {
		.create-plan {
			color: #000;
		}
	}
	.create-plan {
		color: #ffb822;
		&:hover {
			color: #000;
		}
	}
}

.MuiTableCell-root,
.MuiTypography-body2 {
	font-family: "Poppins", sans-serif !important;
}

.card-body.custom-card {
	padding: 0 20px !important;
	margin: 0 auto;
}
.lightbox-cert-html {
	.fslightbox-source-inner {
		width: 100%;
	}

	.fslightbox-source {
		width: 100% !important;
		// max-width: 840px;
	}

	.fslightbox-container {
		min-height: 100vh !important;
    overflow-y: scroll;
    .fslightbox-nav {
      .fslightbox-toolbar > div:first-child{
        display: none;
      }
    }
	}
}
.lightbox-cert {
	.fslightbox-source-inner {
		width: 100%;
	}

	.fslightbox-source {
		width: 100% !important;
		max-width: 840px;
	}

	.fslightbox-container {
		min-height: 100vh !important;
	}
}

.course-introduction {
	border: 1px solid #eee;
	padding: 10px;
	text-align: center;
	cursor: pointer;
	background: #eee;
	font-weight: bold;
}

.course-introduction-has-content {
  border: 1px solid #eee;
  padding: 15px;
  cursor: pointer;
  box-shadow: 1px 1px #eee;
}
.li-content{
  border: 1px solid #eee;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background: #eff3f5;
  box-shadow: 2px 3px #eee;
  margin-bottom: 10px;
  .form-control:focus, .form-control:active{
    background: transparent;

  }
}
.group-image-sort {
	display: flex;
	align-items: center;
}

.input-sesion-name {
	border: none;
	border-bottom: 1px solid;
	border-radius: 0px;
  background: transparent;
}

.open-session {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	cursor: pointer;
  margin-top: 15px;
  .anticon-down, .anticon-up{
    &:hover {
      color: #2e40d4;
      border: 1px solid;
      padding: 2px;
      border-radius: 5px;
    }
  }
  .anticon-delete{
    &:hover {
      color: #d71919;
      border: 1px solid;
      padding: 2px;
      border-radius: 5px;
    }
  }
  .open-span{
    margin-left: 2px;
    &:hover {
      color: #2e40d4;
    }
  }
  
}

.custom-list {
	width: 100%;
	cursor: grab;
	display: contents;
}

.section-upload {
	// .ant-upload.ant-upload-select-picture-card {
	// 	width: 10vw;
	// 	height: 17vh;
	// }
	.ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card {
		width: 10vw;
		height: 100%;
	}
}

.content-section {
	height: 60px;
	border: 1px dotted;
	margin-bottom: 10px;
	margin-top: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
  &:hover {
		border-color: #2e40d4;
    color: #2e40d4;
	}
}
.list-icon-wapper {
	width: 85px;
	height: 60px;
	margin-top: 15px !important;
	margin: 0 auto;
}

.group-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 15px;
}

.active-group {
	background: #4caf5029;
	text-align: center;
	box-shadow: #00000042 1.95px 1.95px 2.6px;
	padding: 5px;
	min-width: 75%;
}

.group-radio-choice {
	display: flex;
	align-items: center;
	.del-choice-img {
		display: flex;
		align-items: flex-start;
		span {
			color: red;
			border: 1px solid #a39898;
			border-radius: 3px;
			padding: 3px;
		}
	}
}

.group-sort-ranking {
	display: flex;
	align-items: center;
	justify-content: center;
}

.group-lesson {
	display: flex;
	justify-content: center;
}

.cursor-pointer {
	cursor: pointer;
}
.item-blank {
	position: relative;
}
.remove-item-blank {
	position: absolute;
	top: -100%;
	cursor: pointer;
	right: -10%;
}
.view-fill-blank {
	border: 1px solid #b9b6b6;
	padding: 6px 20px;
	margin-left: 20px;
}

.PhoneInput{
  display: flex !important;
}

.popover{
  position: absolute;
  z-index: 2;
  left: 50%;
}

.ant-popover-inner{
  background: #464343;
  border-radius: 5px;
  .ant-popover-inner-content{
    color: #fff;
    font-weight: 100;
  }
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow{
  border-right-color: #464343;
  border-bottom-color: #464343
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img{
  -o-object-fit: contain;
  object-fit: contain
}
.custom-col-9{
  max-width: calc(100% - 9% - 130px);
  padding-right: 0;
  padding-left: 20px;
}
.custom-col-2{
  max-width: 104px;
}

.ant-picker:hover, .ant-picker-focused{
  border-color: #9aabff;
}

.ant-select-single .ant-select-selector::after, 
.ant-select-single .ant-select-selector 
.ant-select-selection-item::after, .ant-select-single 
.ant-select-selector .ant-select-selection-placeholder::after{
  margin-top: 4px;
}

.ant-select.form-control.ant-select-disabled {
	background: #f5f5f5;
}

.ant-select-single .ant-select-selector .ant-select-selection-item:after, 
.ant-select-single .ant-select-selector .ant-select-selection-placeholder:after, 
.ant-select-single .ant-select-selector:after{
  margin-top: 4px;
}

.ant-select-disabled{
  .ant-select-selector{
    .ant-select-selection-item{
      color: #545557;
    }
  }
}